import React from 'react';
import './styles.css';

export interface HeaderMenuItemsWebProps {
  menuItems: React.ReactNode;
}

export const HeaderMenuItemsWeb = ({ menuItems }: HeaderMenuItemsWebProps) => {
  return (
    <div className="ModernMarvel--header_container">
      {menuItems}
    </div>
  );
};
import React from 'react'
// Customizable Area Start
import FooterController, { configJSON } from './FooterController.web';
import './assets/Footer.css';
import { withRouter } from 'react-router-dom';
import { withDialog } from '../../../ss-cms-common-components/src/Dialog/withDialog';
import withLoader from '../../../ss-cms-common-components/src/HOC/withBrandingSpinner.web';
const image_playstorelogo = require('./assets/googleplay.png');
const image_appstorelogo = require('./assets/appstore.png');
interface IUserLink {
  id: number;
  title: string;
  position: number;
  help_type: string;
  page_type_name: string;
  page_slug: string;
}
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { KeyboardArrowDownRounded } from '@material-ui/icons';
// Customizable Area End

class Footer extends FooterController {
  // Customizable Area Start
  renderLogo = () => {
    if (!this.state.designDetails.header.storeLogo) return (<div
      style={{
        fontFamily: `${this.state.designDetails.header.storeTextLogoDetails.logoFont}`,
        fontSize: 24,
        fontWeight: 500,
        letterSpacing: "-0.02em",
        color: `${this.state.designDetails.header.storeTextLogoDetails.logoColor}`,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
    }}>{this.state.customerServices.storeName}</div>)
    return <img src={this.state.designDetails.header.storeLogo} alt="logo" />
  }

  renderSocialLinks = () => {
    return (
      <div className="AppFooter__FollowUsOn">
        <span>{configJSON.followUsOnTitle}</span>
        <ul>
          {this.state.designDetails.footer.instagramUrl && 
            <li><a target='_blank' href={this.correctUrl(this.state.designDetails.footer.instagramUrl)}><div>{instagramSvg}</div></a></li>}
          {this.state.designDetails.footer.youtubeUrl && 
            <li><a target='_blank' href={this.correctUrl(this.state.designDetails.footer.youtubeUrl)}><div>{youtubeSvg}</div></a></li>}
          {this.state.designDetails.footer.facebookUrl && 
          <li><a target='_blank' href={this.correctUrl(this.state.designDetails.footer.facebookUrl)}><div>{facebookSvg}</div></a></li>}
          {this.state.designDetails.footer.twitterUrl && 
            <li><a target='_blank' href={this.correctUrl(this.state.designDetails.footer.twitterUrl)}><div>{twitterSvg}</div></a></li>}
          {this.state.designDetails.footer.linkedInUrl && 
            <li><a target='_blank' href={this.correctUrl(this.state.designDetails.footer.linkedInUrl)}><div>{linkedinSvg}</div></a></li>}
        </ul>
      </div>
    )
  }

  renderAppLinks = () => {
    return (
      <div className="AppFooter__DownloadApp">
        <span>{configJSON.downloadAppTitle}</span>
        <ul>
          {this.state.designDetails.footer.playStoreUrl &&
            <li><a href={this.correctUrl(this.state.designDetails.footer.playStoreUrl)} target='_blank'><img src={image_playstorelogo} alt="playstore logo" /></a></li>}
          {this.state.designDetails.footer.appStoreUrl &&
            <li><a href={this.correctUrl(this.state.designDetails.footer.appStoreUrl)} target='_blank'><img src={image_appstorelogo} alt="appstore logo" /></a></li>}
        </ul>
      </div>
    )
  }

  renderLinks = () => {
    if (!this.state.designDetails.footer.showSocialLinks && !this.state.designDetails.footer.showAppLinks) return null;
    return (
      <div className="AppFooter__SocialLinks">
        {this.state.designDetails.footer.showSocialLinks && this.renderSocialLinks()}
        {this.state.designDetails.footer.showAppLinks && this.renderAppLinks()}
      </div>
    )
  }

  renderMobileAccordion = (title: string, content: any) => {
    return (
      <Accordion className='AppFooter__Accordion' square>
        <AccordionSummary
          disableRipple
          disableTouchRipple
          expandIcon={<KeyboardArrowDownRounded />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          style={{ padding: 0 }}
        >
          <span className='AppFooter__Accordion__Title'>{title}</span>
        </AccordionSummary>
        <AccordionDetails
          style={{ padding: 0 }}
        >
          {content}
        </AccordionDetails>
      </Accordion>
    )
  }

  renderSections = () => {
    const renderQuickLinks = () => (
        configJSON.quickLinks.map((item: { id: string, url: string, title: string }) => (
          <ul key={item.id}>
            <li><a href={item.url}>{item.title}</a></li>
          </ul>
        ))
    )

    const renderUsefulLinks = () => (
        this.state.userLinks.map((item: IUserLink) => (
          <ul key={item.id}>
            <li><a href={`${configJSON.helpCenter}/${item.page_slug}`}>{item.title}</a></li>
          </ul>
        ))
    )

    const renderContactDetails = () => (
      <>
        {this.state.customerServices.customerPhone && (
          <ul>
            <li>
              <a href={`tel:${this.state.customerServices.customerPhone}`}>
                {configJSON.phoneTitle} {this.state.customerServices.customerPhone}
              </a>
            </li>
          </ul>
        )}
        {this.state.customerServices.customerEmail && (
          <ul>
            <li>
              <a href={`mailto:${this.state.customerServices.customerEmail}`}>
                {configJSON.emailTitle} {this.state.customerServices.customerEmail}
              </a>
            </li>
          </ul>
        )}
        {this.state.customerServices.customerAddress && (
          <ul>
            <li>
              {configJSON.addressTitle} {this.state.customerServices.customerAddress}
            </li>
          </ul>
        )}
      </>
    )

    return (
      <>
        <div className="AppFooter__MobileSections">
          {this.renderMobileAccordion(configJSON.quickLinksTitle, 
            <div className="AppFooter__QuickLinks">{renderQuickLinks()}</div>)}
          {this.state.userLinks.length > 0 && 
            this.renderMobileAccordion(configJSON.usefulLinksTitle, 
              <div className="AppFooter__UsefulLinks">{renderUsefulLinks()}</div>)}
          {this.state.designDetails.footer.showContactDetails && 
            this.renderMobileAccordion(configJSON.contactUsTitle, 
              <div className="AppFooter__CustomerService">{renderContactDetails()}</div>)}
        </div>
        <div className="AppFooter__DesktopSections">
          <div className="AppFooter__QuickLinks">
            <span>{configJSON.quickLinksTitle}</span>
            {renderQuickLinks()}
          </div>
          <div className="AppFooter__UsefulLinks">
            <span>{configJSON.usefulLinksTitle}</span>
            {renderUsefulLinks()}
          </div>
          {this.state.designDetails.footer.showContactDetails && (
            <div className="AppFooter__CustomerService">
              <span>{configJSON.contactUsTitle}</span>
              {renderContactDetails()}
            </div>
          )}
        </div>
      </>
    )
  }
  
  render() {
    return (
      <div className='AppFooter'>
        <div className="AppFooter__Logo" title={this.state.customerServices.storeName}>
          {this.renderLogo()}
        </div>
        {this.renderSections()}
        {this.renderLinks()}
        <div className="AppFooter__CopyRight">
          <span>{this.state.designDetails.footer.copyRightText}</span>
        </div>
      </div>
    )
  }
  // Customizable Area End
}

// Customizable Area Start
export default withRouter(withDialog(withLoader(Footer)));

const facebookSvg = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.6875 10C19.6875 12.3958 18.9062 14.5053 17.3438 16.3281C15.7812 18.125 13.8411 19.2058 11.5234 19.5703V12.8125H13.789L14.2188 10H11.5234V8.16406C11.5234 7.17448 12.0443 6.67969 13.086 6.67969H14.2969V4.29688C13.5677 4.16666 12.8515 4.10156 12.1484 4.10156C11.3932 4.10156 10.7422 4.24479 10.1953 4.53125C9.67447 4.81771 9.25781 5.2474 8.94531 5.82031C8.63281 6.39323 8.47656 7.07031 8.47656 7.85156V10H6.01562V12.8125H8.47656V19.5703C6.15885 19.2058 4.21875 18.125 2.65625 16.3281C1.09375 14.5053 0.3125 12.3958 0.3125 10C0.3125 7.31771 1.25 5.03906 3.125 3.16406C5.02604 1.26303 7.31771 0.3125 10 0.3125C12.6823 0.3125 14.961 1.26303 16.836 3.16406C18.737 5.03906 19.6875 7.31771 19.6875 10Z" fill="#222222"/>
  </svg>
)

const twitterSvg = (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0481414 0.625L7.67163 10.822L0 19.1125H1.72658L8.44308 11.854L13.8699 19.1125H19.7454L11.693 8.34199L18.8337 0.625H17.1071L10.9216 7.30994L5.92375 0.625H0.0481414ZM2.5872 1.89725H5.28646L17.206 17.84H14.5068L2.5872 1.89725Z" fill="#222222"/>
  </svg>
)

const instagramSvg = (
  <svg width="20" height="20" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.87563 5.11816C7.33531 5.11816 5.23047 7.18671 5.23047 9.76333C5.23047 12.3399 7.29902 14.4085 9.87563 14.4085C12.4522 14.4085 14.5208 12.3036 14.5208 9.76333C14.5208 7.223 12.416 5.11816 9.87563 5.11816ZM9.87563 12.7391C8.24257 12.7391 6.89982 11.3964 6.89982 9.76333C6.89982 8.13026 8.24257 6.78752 9.87563 6.78752C11.5087 6.78752 12.8514 8.13026 12.8514 9.76333C12.8514 11.3964 11.5087 12.7391 9.87563 12.7391Z" fill="black"/>
    <path d="M14.7009 6.06187C15.2821 6.06187 15.7533 5.59069 15.7533 5.00945C15.7533 4.42822 15.2821 3.95703 14.7009 3.95703C14.1196 3.95703 13.6484 4.42822 13.6484 5.00945C13.6484 5.59069 14.1196 6.06187 14.7009 6.06187Z" fill="black"/>
    <path d="M17.4234 2.28771C16.4798 1.30787 15.1371 0.799805 13.6129 0.799805H6.1371C2.97984 0.799805 0.875 2.90464 0.875 6.0619V13.5014C0.875 15.0619 1.38306 16.4046 2.39919 17.3845C3.37903 18.328 4.68548 18.7998 6.17339 18.7998H13.5766C15.1371 18.7998 16.4435 18.2917 17.3871 17.3845C18.3669 16.4409 18.875 15.0982 18.875 13.5377V6.0619C18.875 4.53771 18.3669 3.23126 17.4234 2.28771ZM17.2782 13.5377C17.2782 14.6627 16.879 15.57 16.2258 16.1869C15.5726 16.8038 14.6653 17.1305 13.5766 17.1305H6.17339C5.08468 17.1305 4.17742 16.8038 3.52419 16.1869C2.87097 15.5337 2.54435 14.6264 2.54435 13.5014V6.0619C2.54435 4.97319 2.87097 4.06593 3.52419 3.41271C4.14113 2.79577 5.08468 2.46916 6.17339 2.46916H13.6492C14.7379 2.46916 15.6452 2.79577 16.2984 3.449C16.9153 4.10222 17.2782 5.00948 17.2782 6.0619V13.5377Z" fill="black"/>
  </svg>
)

const linkedinSvg = (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16" fill="none">
    <path fillRule="evenodd" clipRule="evenodd" d="M13.4037 2H2.52174C2.2236 2 2 2.2236 2 2.52174V13.4783C2 13.7019 2.2236 13.9255 2.52174 13.9255H13.4783C13.7764 13.9255 14 13.7019 14 13.4037V2.52174C13.9255 2.2236 13.7019 2 13.4037 2ZM5.50319 12.1366H3.7889V6.47205H5.57772V12.1366H5.50319ZM4.68329 5.72671C4.08702 5.72671 3.63981 5.20497 3.63981 4.68323C3.63981 4.08696 4.08702 3.63975 4.68329 3.63975C5.27956 3.63975 5.72677 4.08696 5.72677 4.68323C5.65223 5.20497 5.20503 5.72671 4.68329 5.72671ZM12.1366 12.1366H10.3478V9.37888C10.3478 8.70808 10.3478 7.8882 9.45339 7.8882C8.55898 7.8882 8.40991 8.63354 8.40991 9.37888V12.2112H6.62109V6.47205H8.33538V7.21739C8.55898 6.77019 9.15525 6.32298 9.97513 6.32298C11.764 6.32298 12.0621 7.51553 12.0621 9.00621V12.1366H12.1366Z" fill="black"/>
  </svg>
)

const youtubeSvg = (
  <svg width="20" height="20" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.0853 2.41947C18.8672 1.59829 18.2246 0.951551 17.4088 0.732071C15.9299 0.333252 10 0.333252 10 0.333252C10 0.333252 4.07019 0.333252 2.59133 0.732071C1.77546 0.951585 1.13289 1.59829 0.914805 2.41947C0.518555 3.9079 0.518555 7.01339 0.518555 7.01339C0.518555 7.01339 0.518555 10.1189 0.914805 11.6073C1.13289 12.4285 1.77546 13.0483 2.59133 13.2678C4.07019 13.6666 10 13.6666 10 13.6666C10 13.6666 15.9299 13.6666 17.4088 13.2678C18.2246 13.0483 18.8672 12.4285 19.0853 11.6073C19.4815 10.1189 19.4815 7.01339 19.4815 7.01339C19.4815 7.01339 19.4815 3.9079 19.0853 2.41947ZM8.06064 9.83294V4.19384L13.0169 7.01346L8.06064 9.83294Z" fill="#222222"/>
  </svg>
)

// Customizable Area End
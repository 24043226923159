
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.createCartApiEndPoint = "/bx_block_shopping_cart/carts";
exports.storeDetailsEndpoint = "/bx_block_store_details/store_details";
exports.addItemToCartApiEndPoint = "/bx_block_shopping_cart/cart_items";
exports.postAPiMethod = "POST";
exports.productAPiEndPoint = "bx_block_catalogue/catalogues";
exports.productAPiEndPointSlug = "bx_block_catalogue/catalogues/search_by_slug?slug_name=";
exports.productAPiMethod = "GET";
exports.productApiContentType = "application/json";
exports.description = "Description";
exports.reviews = "Reviews";
exports.details = "Details";
exports.home = "Home";
exports.noImageAvailable = "No Image Available";
exports.off = "off";
exports.queryParameters = {
  Category: "[query][category_id]",
  SubCategory: "[query][sub_category_id]",
};
exports.outofStock = "Out of stock"
exports.allproducts = "All Products"
exports.productNotActive = "This product is currently not being sold."
exports.notActiveArr = ["archived", "draft"]
exports.stockQtyExceeds = "Stock quantity exceeds the available quantity"


exports.prodctViewSuccess = "product_detail_view_success"
exports.addToCartEvent = "add_to_cart"
exports.addToCartSuccessEvent = "add_to_cart_success"

exports.youtubeImgUrl = 'https://img.youtube.com/vi/{video_id}/hqdefault.jpg';
// Customizable Area End

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDqdeygjmlt7v_1bbNeuCSTOsy_ppmWYes",
  authDomain: "store-demo-ec73d.firebaseapp.com",
  projectId: "store-demo-ec73d",
  storageBucket: "store-demo-ec73d.appspot.com",
  messagingSenderId: "700804479670",
  appId: "1:700804479670:web:45b4a89b0a0bc08c4cc417",
  measurementId: "G-D26V89HRQ6"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

export { analytics };

import React, { Component, Suspense, lazy } from "react";
// Customizable Area Start
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { CircularProgress } from "@material-ui/core";

const Catalogue = lazy(() => import("../../../blocks/catalogue/src/Catalogue.web"));
const LandingPage = lazy(() => import("../../../blocks/landingpage/src/LandingPage.web"));
const ContactUs = lazy(() => import("../../contactus/src/ContactUs.web"));
const ShoppingCart = lazy(() => import("../../shoppingcart/src/ShoppingCart.web"));
const PaymentSuccessScreen = lazy(() => import("../../shoppingcart/src/PaymentSuccessScreen"))
const PaymentFailure = lazy(() => import("../../shoppingcart/src/PaymentFailure.web"));
const Tappaychargeidloader = lazy(() => import("../../tappaymentsintegration/src/Tappaychargeidloader.web"));
const AddAddress = lazy(()=> import("../../addressmanagement/src/AddAddress.web"));
const AddressManagement = lazy(()=> import("../../addressmanagement/src/AddressManagement.web"));
const RecentOrders = lazy(() => import("../../user-profile-basic/src/UserRecentOrdersBlock.web"));
const RecentOrderDetail = lazy(() => import("../../user-profile-basic/src/UserOrderDetailsBlock.web"));
const UserProfile = lazy(() => import("../../user-profile-basic/src/UserProfileBasic.Web"));
const UserOrderDetails = lazy(() => import("../../user-profile-basic/src/UserOrderDetailsBlock.web"));
/* Admin Routes */
const AdminDashboardNew = lazy(() => import("../../../blocks/AdminDashboard/src/AdminDashboardNew.web"));
const EmailAccountLogin = lazy(() => import("../../../blocks/emailaccountlogin/src/Login.web"));
const EmailAccountForgotPassword = lazy(() => import("../../../blocks/emailaccountforgotpassword/src/ForgotPassword.web"));
const AdminEmailTemplates = lazy(() => import("../../../blocks/adminemailtemplates/src/EmailTemplates.web"));
const AdminEmailTemplatesCreate = lazy(() => import("../../../blocks/adminemailtemplates/src/EmailTemplatesCreate.web"));
const AdminStaticPage = lazy(() => import("../../../blocks/adminstaticpage/src/StaticPageTable.web"));
const AdminStaticPageCreate = lazy(() => import("../../../blocks/adminstaticpage/src/CreateStaticPage.web"));
const AdminImageCatalogue = lazy(() => import("../../../blocks/adminimagecatalogue/src/ImageCatalogue.web"));
const AdminImageCatalogueBulkUpload = lazy(() => import("../../../blocks/adminimagecataloguebulkupload/src/ImageCatalogueBulkUpload.web"));
const AdminEditImage = lazy(() => import("../../../blocks/admineditimage/src/EditImage.web"));
const AdminCustomerFeedback = lazy(() => import("../../../blocks/admincustomerfeedback/src/CustomerFeedback.web"));
const AdminCustomerFeedbackCreate = lazy(() => import("../../../blocks/admincustomerfeedback/src/CustomerFeedbackCreate.web"));
const AdminStoreDetails = lazy(() => import("../../../blocks/adminstoredetails/src/StoreDetails.web"));
const AdminTaxes = lazy(() => import("../../../blocks/admintaxes/src/Taxes.web"));
const AdminShipping = lazy(() => import("../../../blocks/adminshipping/src/Shipping.web"));
const AdminPromoCode = lazy(() => import("../../../blocks/adminpromocode/src/PromoCode.web"));
const AdminPromoCodeCreate = lazy(() => import("../../../blocks/adminpromocode/src/PromoCodeCreate.web"));
const AdminPaymentIntegration = lazy(() => import("../../../blocks/adminpaymentintegration/src/Payment.web"));
const AdminMobileApp = lazy(() => import("../../../blocks/adminmobileapp/src/MobileApp.web"));
const AdminNotifications = lazy(() => import("../../../blocks/adminnotifications/src/Notifications.web"));
const AdminOtherIntegrations = lazy(() => import("../../../blocks/adminotherintegrations/src/OtherIntegrations.web"));
const AdminAccount = lazy(() => import("../../../blocks/adminaccount/src/AccountDetails.web"));
const AdminOverView = lazy(() => import("../../../blocks/servicespecificsettingsadmin2/src/Servicespecificsettingsadmin2.web"));
const AdminProducts = lazy(() => import("../../AdminProducts/src/Products.web"));
const AdminAddProduct = lazy(() => import("../../AdminProducts/src/components/AddProduct/AddProduct"));
const AdminCategories = lazy(() => import("../../AdminCategories/src/categories/Categories"));
const AdminAddCategory = lazy(() => import("../../AdminCategories/src/addCategory/AddCategory"));
const AdminBrands = lazy(() => import("../../AdminBrands/src/Brands.web"));
const AdminCustomers = lazy(() => import("../../AdminCustomers/src/CustomersInboundQueries.web"));
const AdminVariants = lazy(() => import("../../AdminVariants/src/Variants.web"));
const AdminDashboard = lazy(() => import("../../AdminDashboard/src/Dashboard.web"));
const AdminOrders = lazy(() => import("../../AdminOrders/src/Orders.web"));
const AdminOrderDetails = lazy(() => import("../../AdminOrders/src/OrderDetails.web"));
const Branding = lazy(() => import('../../AdminWebsiteBuilder/src/Branding.web'));
const AdminSiteMap = lazy(() => import('../../searchengineoptimisationseo/src/SiteMpas.web'));
const Adminconsole2 = lazy(() => import('../../adminconsole2/src/Adminconsole2.web').then(module => ({ default: module.NewAdminconsole2 })));
const EditCustomer = lazy(() => import('../../adminconsole2/src/EditCustomer').then(module => ({ default: module.NewEditCustomer })));
const AdminMarketing = lazy(() => import('../../AdminMarketing/src/PopUpForms/PopUpForms.web'));
import { NewAnalytics } from "../../analytics/src/Analytics.web"
const ConfigureSeo = lazy(() => import("../../searchengineoptimisationseo/src/ConfigureSeo.web"));
const ConfigureSeoEdit = lazy(() => import("../../searchengineoptimisationseo/src/ConfigureSeoEdit.web"));
const SearchengineoptimisationLanding = lazy(() => import("../../searchengineoptimisationseo/src/SeoLandingPage.web"));
const AdminPopUpFormTable = lazy(() => import('../../storecontentmanagement2/src/PopUpFormTable.web'));
const AdminResponseTable = lazy(() => import('../../storecontentmanagement2/src/ResponseTable.web'));
import { SEOWithWrapper } from "../../searchengineoptimisationseo/src/Searchengineoptimisationseo.web";
const SharableTab = lazy(() => import("../../adminpromocode/src/ShareStoreTab.web"));
// const cfPlatformMigration = lazy(() => import("../../cfplatformmigration/src/Cfplatformmigration.web").then(module => ({ default: module.CfplatformmigrationDefault })));
const cfPlatformMigration = lazy(() => import("../../cfplatformmigration/src/Cfplatformmigration.web"));
const AmazonShopPageLink = lazy(() => import('../../cfplatformmigration/src/AmazonShopPageLink.web').then(module => ({ default: module.NewAmazonShopPageLink })));
const AmazonProductsImport = lazy(() => import('../../cfplatformmigration/src/AmazonProductsImport.web').then(module => ({ default: module.NewAmazonProductsImport })));
const fallbackLoader = 
  <div style={{
    width: "100%",
    height: "100%",
    position: "relative",
  }}>
    <Skeleton 
      variant="rect"
      width="100%"
      height="100%"
      animation="wave"
      style={{ backgroundColor: "#fff" }}
    />
    <CircularProgress 
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        color: "#f0f0f0",
        animation: "none" 
      }}
    />
  </div>;

// Customizable Area End
class WebRoutes extends Component {
  // Customizable Area Start
  async componentDidMount(): Promise<void> {
    window.history.scrollRestoration = 'manual';
    window.scrollTo(0, 0);
  }
  isAdminRoute = window.location.href.includes('admin');

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <BrowserRouter>
        <Suspense fallback={fallbackLoader}>
          <Switch>
            {/* Web Routes */}
            {!this.isAdminRoute ? <>
              <Route path={["/", "/setnewpassword"]} exact render={(props: any) => <LandingPage {...props} />} />
              <Route path="/catalogue/:categorySlug?/:subCategorySlug?" exact render={(props: any) => <Catalogue {...props} />} />
              <Route path="/contact-us" exact render={(props: any) => <ContactUs {...props} />} />
              <Route exact path="/shopping-cart" component={ShoppingCart} />
              <Route exact path="/payment-success" component={PaymentSuccessScreen} />
              <Route exact path='/payment-failure' component={PaymentFailure} />
              <Route exact path="/payment_loading" component={Tappaychargeidloader} />
              <Route exact path="/profile/address-management" component={AddressManagement} />
              <Route exact path="/profile/address-management/:id" component={AddAddress} />
              <Route exact path="/profile/orders" component={RecentOrders} />
              <Route exact path="/profile/orders/:id" component={RecentOrderDetail} />
              <Route exact path="/profile" component={UserProfile} />
              <Route exact path="/userOrderDetails/:id" component={UserOrderDetails} />
            </>
              :
              <>
                <Route exact path="/admin/" component={AdminDashboardNew} />
                <Route exact path="/admin/EmailAccountLogin" component={EmailAccountLogin} />
                <Route exact path="/admin/adminDashboardNew" component={AdminDashboardNew} />
                <Route exact path="/admin/promotions" component={SharableTab} />
                <Route
                  exact
                  path="/admin/EmailAccountForgotPassword"
                  component={EmailAccountForgotPassword}
                />
                <Route
                  exact
                  path="/admin/AdminEmailTemplates"
                  component={AdminEmailTemplates}
                />
                <Route
                  //exact
                  path="/admin/AdminEmailTemplatesCreate/:id"
                  component={AdminEmailTemplatesCreate}
                />
                <Route exact path="/admin/AdminStaticPage" component={AdminStaticPage} />
                <Route exact path="/admin/Branding" component={Branding} />
                <Route
                  exact
                  path="/admin/AdminStaticPageCreate/:id?"
                  component={AdminStaticPageCreate}
                />
                <Route
                  exact
                  path="/admin/AdminImageCatalogue"
                  component={AdminImageCatalogue}
                />
                <Route
                  exact
                  path="/admin/AdminImageCatalogueBulkUpload"
                  component={AdminImageCatalogueBulkUpload}
                />
                <Route exact path="/admin/AdminEditImage/:id" component={AdminEditImage} />
                <Route
                  exact
                  path="/admin/AdminCustomerFeedback"
                  component={AdminCustomerFeedback}
                />
                <Route
                  exact
                  path="/admin/AdminCustomerFeedbackCreate/:id?"
                  component={AdminCustomerFeedbackCreate}
                />
                <Route exact path="/admin/AdminStoreDetails" component={AdminStoreDetails} />
                <Route exact path="/admin/AdminTaxes" component={AdminTaxes} />
                <Route exact path="/admin/AdminShipping" component={AdminShipping} />
                <Route exact path="/admin/AdminPromoCode" component={AdminPromoCode} />
                <Route
                  exact
                  path="/admin/AdminPromoCodeCreate/:id?"
                  component={AdminPromoCodeCreate}
                />
                <Route
                  exact
                  path="/admin/AdminPaymentIntegration"
                  component={AdminPaymentIntegration}
                />
                <Route exact path="/admin/AdminMobileApp" component={AdminMobileApp} />
                <Route exact path="/admin/AdminSEO" component={SEOWithWrapper} />
                <Route
                  exact
                  path="/admin/AdminNotifications"
                  component={AdminNotifications}
                />
                <Route
                  exact
                  path="/admin/AdminOtherIntegrations"
                  component={NewAnalytics}
                />
                <Route exact path="/admin/sercivesspecificsetting/AdminAccount" component={AdminAccount} />
                <Route exact path="/admin/sercivesspecificsetting" component={AdminOverView} />
                <Route exact path="/admin/products" component={AdminProducts} />
                <Route exact path="/admin/products/add-product/:id?" component={AdminAddProduct} />
                <Route exact path="/admin/products/archived" component={AdminProducts} />
                <Route exact path="/admin/categories" component={AdminCategories} />
                <Route exact path="/admin/categories/add-category/:id?" component={AdminAddCategory} />
                <Route exact path="/admin/brands" component={AdminBrands} />
                <Route exact path="/admin/inbound-queries" component={AdminCustomers} />
                <Route exact path="/admin/variants" component={AdminVariants} />
                <Route exact path="/admin/customers" component={Adminconsole2} />
                <Route exact path="/admin/customers/:id" component={EditCustomer} />
                <Route exact path="/admin/orders" component={AdminOrders} />
                <Route exact path="/admin/orders/:id" component={AdminOrderDetails} />
                <Route exact path='/admin/configure-seo' component={ConfigureSeo} />
                <Route exact path='/admin/configure-seo/:id' component={ConfigureSeoEdit} />
                <Route exact path='/admin/seo-checklist' component={SearchengineoptimisationLanding} />
                <Route exact path="/admin/sitemap" component={AdminSiteMap} />
                <Route exact path="/admin/marketing" component={AdminPopUpFormTable} />
                <Route exact path="/admin/marketing/response/:id" component={AdminResponseTable} />
                <Route exact path="/admin/marketing/lead-generation/:id?" component={AdminMarketing} />
                <Route exact path="/admin/products/cf-plotform-migration" component={cfPlatformMigration} />
                <Route exact path="/admin/products/amazon-shop-page-link" component={AmazonShopPageLink} />
                <Route exact path="/admin/amazon-products-import" component={AmazonProductsImport} />
              </>}
          </Switch>
        </Suspense>
      </BrowserRouter>
    );
    // Customizable Area End
  }
}

export default WebRoutes;

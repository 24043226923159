import * as configcat from "configcat-js";

export let configCatClient = configcat.getClient(
  "configcat-sdk-1/C-LcCEbe4kmA-8qktTmGhg/N1FIKmtLFUqS_Sw9FvbW0g"
);

export const baseURL = require("../../framework/src/config.js").baseURL;
let isProduction = false;
if (baseURL.includes("ruby")) {
  //this is builder form url, so we can check for dev, stage, uat and prod
  isProduction = baseURL.includes(".prod.") && baseURL.includes("builder");
} else {
  isProduction = true;
}

if (isProduction) {
  configCatClient = configcat.getClient(
    "configcat-sdk-1/C-LcCEbe4kmA-8qktTmGhg/d7BPn6V7xUSbCqD2H29Usg"
  );
}

// Define the type for a template
type DesignTemplate = {
  theme: string;
  header: string;
  category: string;
};

// Define the template array
const designTemplates: DesignTemplate[] = [
  {
    theme: "Classic Elegance",
    header: "Elevate",
    category: "Ethos",
  },
  {
    theme: "Modern Marvel",
    header: "Horizon",
    category: "Vivid",
  },
];

// Function to get template
export const getDesignTemplate = (themeName?: string): DesignTemplate => {
  const defaultTemplate = designTemplates[0];

  if (!themeName) return defaultTemplate;

  return (
    designTemplates.find(
      (template) => template.theme.toLowerCase() === themeName.toLowerCase()
    ) ?? defaultTemplate
  );
};

export const themeFeatureWithoutWebsiteBuilder = true;
